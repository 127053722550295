// #TODO: Make the fonts look right.

$hafla: #ff794d;
$border-color: #ddd;
$highlight-color: #ea4842;

@mixin fixed-width($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

@mixin fixed-margin($margin) {
  margin: $margin;
}

@mixin fixed-square($size) {
  @include fixed-width($size);
  height: $size;
  min-height: $size;
  max-height: $size;
}

@mixin text-box {
  input {
    width: 90%;
    padding: 0 0.5em;
    border-width: 0.5px;
    border-style: solid;
    border-radius: 0.2em;
  }
}

@mixin number {
  input {
    text-align: right;
  }
}

.circular {
  border-radius: 50%;
}

.clickable {
  cursor: pointer;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small {
  width: 2rem;
  height: 2rem;
}

.content {
  padding: 1em;
}

.display-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.display-row-unit-item {
  display: flex;
  flex-direction: row;
  width: 90%;
}

.overflow {
  overflow: hidden;
}

.summary-field-input {
  width: 70px;
}

.cart {
  textarea {
    height: 6em;
    border-radius: 0.5em;
    padding: 0.75em 1em;
  }

  textarea.note-height-input {
    height: 40px;
    margin: 0px;
  }

  .cart-item {
    textarea {
      @include fixed-margin(0 0 0.5em 0);
    }
  }

  .cart-summary {
    textarea {
      @include fixed-margin(0.5em 0);
    }
  }

  // #TODO: Introduce input styles across the project.
  textarea,
  input {
    font-size: 0.9em;
    border: 1px solid $border-color;

    &:focus {
      border-color: darken($color: $border-color, $amount: 20);
      outline: none;
    }

    &::selection {
      color: white;
      background-color: lighten($color: $hafla, $amount: 5);
    }
  }

  select {
    width: 100%;
    height: 1.5em;
    outline: none;
    border-width: initial;
  }
}

.cart-header-text {
  --tw-text-opacity: 1;
  color: rgba(113, 113, 113, var(--tw-text-opacity));
  font-weight: 500;
}

.cart-with-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;

  .rounded-box {
    // #NOTE: The global value is overridden as per the designs.
    // #TODO: Check whether this could be the global value.
    border-radius: 0.65em;
  }

  .price-notes-icon {
    vertical-align: top;
  }

  .cart-item {
    .surface-web & {
      display: flex;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: 0px;
      }

      &.header {
        padding-bottom: 0.5em;
      }

      .product-image {
        @include fixed-square(4rem);
      }

      & > div {
        padding: 0.5em 0.25em 0;

        &:nth-child(1) {
          margin-left: 1em;
        }

        &:nth-last-child(1) {
          margin-right: 1em;
        }

        &.product-link {
          @include fixed-width(3em);
        }

        &.item {
          width: 35%;
          min-width: 10em;
        }

        &.order-item {
          width: 35%;
          min-width: 10em;
        }

        &.actions {
          @include fixed-width(2em);
        }
      }
    }

    .role-crm & {
      align-items: flex-start;
      margin-top: 1.5em;
    }

    .role-customer.surface-web & {
      align-items: center;
    }

    .role-crm.surface-web & > div {
      &.price {
        @include fixed-width(6em);
        @include text-box;
        @include number;
      }

      &.big-price {
        @include fixed-width(9em);
        @include text-box;
        @include number;
      }

      &.quantity {
        @include fixed-width(6em);
      }

      &.order-quantity {
        @include fixed-width(5em);
      }

      &.cart-quantity {
        @include fixed-width(8em);
      }

      &.delivery-charge {
        @include fixed-width(6em);
      }

      &.description {
        @include fixed-width(16em);
        @include text-box;
      }

      &.partner {
        @include fixed-width(8em);
      }
    }

    .role-customer.surface-web & > div {
      &.price {
        @include fixed-width(9em);
      }

      &.quantity {
        @include fixed-width(8em);
      }

      &.small-price {
        @include fixed-width(7em);
      }
    }

    .surface-mobile & {
      padding: 0.6em;
      display: flex;
      flex-direction: column;
      line-height: 1.3em;
      border-radius: 1em;
      border: 1px solid #ddd;
      box-shadow: 0 0.6em 0.4em -0.4em #c4c0c0;
    }
  }

  .remove-button {
    @extend .circular;
    @extend .small;
    @extend .centered;
    @extend .clickable;

    background-color: #ddd;
  }
}

.qty .fontsize {
  font-size: 16px;
  font-weight: 500;
}

.row-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-with-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1em;
}

.column-1-spilt {
  width: 30%;
}

.column-2-spilt {
  width: 65%;
}

.text-align-right {
  justify-content: end;
  font-weight: 500;
}

.row {
  @extend .display-row;

  & > .spacer {
    width: 1em;
  }
}

.price-notes {
  margin: 1em;
}

.price-notes-margin {
  margin: 1em 1em 0 0;
}

.role-customer.surface-mobile {
  .product-image {
    margin: 1em;
  }

  .price {
    font-weight: 500;
  }
}

.mb-1 {
  margin-bottom: 1em;
}

.stretch {
  flex-grow: 1;
}

.spinner {
  @extend .display-row;
  @extend .centered;

  .button {
    min-width: 1.5em;
  }

  .display {
    @extend .display-row;
    @extend .stretch;
    @extend .centered;
  }
}

.product-name {
  padding: 1em;
  margin-top: -0.5em;
}

.role-customer.surface-mobile {
  .product-name {
    padding: 0.7em 0.5em 0.2em 0;
  }
}

.info {
  color: $highlight-color;
  font-size: 0.8em;

  .surface-mobile & {
    // #TODO: Find the right solution to make the container is just as tall as the text.
    line-height: 1em;
    padding-top: 1em;
  }

  .icon {
    display: inline-block;
    border-radius: 50%;
    border: 0.1em solid $highlight-color;
    @include fixed-width(1.7em);
    min-height: 1.7em;
    line-height: 1.5em;
    text-align: center;
    font-size: 0.7em;
    margin-right: 0.4em;
    margin-top: 0.1em;
    max-height: 1.7em;
    height: 1.7em;
  }
}

.cart-summary .content {
  @include fixed-width(49em);
}

.rfq-list,
.rrfq-list {
  margin-top: 1em;
  overflow: hidden;
  border: 1px solid $highlight-color;
}

.surface-web {
  .quote-request-button {
    float: right;
    margin: 1rem;
  }
}

.request-button-mobile {
  float: right;
  margin: 0.5rem;
}

.rrfq-button {
  width: fit-content;
  float: right;
  margin: 0.5rem;
}

.surface-mobile {
  & .rfq-list {
    border: 0px;
    box-shadow: 0px;
    margin-top: 0;

    .cart-item {
      border: 1px solid $highlight-color;
      margin-top: 1em;
      overflow: hidden;
    }
  }
}

.display-column {
  display: flex;
  flex-direction: column;
}

.disable-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.quantity-display {
  text-align: center;
}

.item-display {
  width: 100%;

  .surface-mobile & {
    margin: 1.7em 1em;
  }
}

.step-background {
  background: linear-gradient(27deg, #de8000 10%, #c85384 45%, #d93b00 90%);
  font-weight: 900;
}

.step-background-inactive {
  font-weight: 900;
}

.box-div {
  background-color: white;
  box-shadow: 2px 2px rgb(0 0 0 / 30%);
  border-radius: 10px;
  width: 38%;
  height: 170px;
  border: none;
  padding: 10px;

  .surface-mobile & {
    height: 105px;
    width: 80%;
  }
}

.stepper-circle {
  font-family: 'Montserrat' !important; // added important due to inner component replaces the font family.
  font-weight: bold;
}

.box-title {
  text-align: center;
  font-weight: 900;
  font-size: 18px;
  padding-bottom: 15px;
  padding-top: 10px;

  .surface-mobile & {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 8px;
  }
}

.box-text {
  text-align: center;
  font-weight: 500;

  .surface-mobile & {
    font-size: 13px;
  }
}

.progress-bar-center {
  text-align: '-webkit-center';
}

.absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  margin: auto;
}

.absolute-center-progress-bar {
  position: relative;
  left: 38%;
  right: 0;
  top: -50px;
  bottom: 0;

  .surface-mobile & {
    left: 20%;
    top: -25px;
  }
}

.secondary-field-display {
  font-size: 0.8em;
  text-align: center;
}

.hr-line {
  height: 3px;
  width: 60px;
  border: 0px;
  background: linear-gradient(
    50deg,
    rgb(220, 124, 10) 7.01%,
    rgb(202, 87, 121) 56.57%,
    rgb(205, 76, 91) 100.37%
  );
}

.circle-about {
  height: 500px;
  width: 500px;
  padding: 10%;
  padding-left: 16%;
  background-color: #ffffff;
  border-radius: 50%;
}

.circle-container {
  width: 600px;
  height: 600px;
  margin: 0;
  padding: 0;
}

.circle-main {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.circle-text-container {
  width: 60%;
  max-width: 70%;
  margin: 0;
  padding: 0;
  position: relative;
  left: 20%;
  top: 20%;
  transform-style: preserve-3d;
}

//#TODO: follow dashed naming convetion in above implementation. i.e. foot-background -> foot-background
.about-section-main {
  background-color: #f5f5f5;
}

.font-about {
  .surface-mobile & {
    text-align: center;
    font-family: 'google-basic';
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    color: #222222;
  }

  .surface-web & {
    font-family: 'google-basic';
    font-weight: 400;
    font-size: 34px;
    letter-spacing: 0.22em;
    text-transform: uppercase;
    color: #222222;
  }
}

.about-text {
  font-family: 'google-basic';
  font-style: normal;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #222222;

  .surface-mobile & {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  .surface-web & {
    font-weight: 559;
    font-size: 19px;
  }
}

.about-hafla-text {
  .surface-mobile & {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
  }

  .surface-web & {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #222222;
  }
}

.about-title-hr-border-mobile {
  margin: auto;
  width: 46px;
  height: 4px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.about-title-hr-border {
  width: 80px;
  height: 6px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
  margin-top: 8px;
}

.about-delivery-hr {
  margin-left: -20%;
  margin-top: 3%;
  margin-bottom: 3%;
  width: 252px;
  height: 7px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.img-center {
  margin: 0 auto;
}

.img-stretch {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
}

.about-event-start-hr {
  margin-top: 3%;
  margin-bottom: 3%;
  width: 252px;
  height: 7px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.about-event-hr-mobile {
  width: 40%;
  height: 4px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.about-delivery-start-hr-mobile {
  width: 30%;
  height: 4px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.about-delivery-end-hr-mobile {
  margin-left: auto;
  width: 20%;
  height: 4px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.about-event-end-hr {
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 24%;
  width: 88px;
  height: 7px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.about-event-planing-hr {
  margin-top: 2%;
  margin-bottom: 3%;
  width: 120px;
  height: 7px;
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    #de8000 -1.52%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.text-section {
  font-size: 20px;
  font-weight: 500;
}

.about-bottom-text {
  font-size: 2.2rem;
  line-height: 3rem;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 1px 1px 1px #3c5c5e;
}

.grid-container-about-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-weight: bold;
}

.thumbnail {
  position: relative;
  display: inline-block;
}

.arrow-background {
  color: #000000;
  width: 10px;
  font-weight: bold;
}

.about-hafla-footer {
  background-image: url('/svg/about-hafla-footer.svg');

  & > div {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.foot-background {
  background-color: #f5f5f5;
  margin-top: 35px;
}

.grid-container {
  display: grid;
  grid-template-columns: 250px 450px 15px 400px;
  background-image: repeating-linear-gradient(
      -21deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      69deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      159deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      249deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    );
  background-size: 0px 100%, 100% 0px, 0px 100%, 40% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.grid-for-blank {
  border-color: black;
  background-image: repeating-linear-gradient(
      -21deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      69deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      159deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      249deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    );
  background-size: 0px 100%, 100% 0px, 0px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.blog-font-style {
  color: #737373;
}

.grid-item {
  grid-template-columns: 8ch auto;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  color: #737373;
  font-family: 'Montserrat';
  padding: 15px;
  padding-left: 25px;
  background-image: repeating-linear-gradient(
      -21deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      69deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      159deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      249deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    );
  background-size: 1px 100%, 100% 0px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.grid-header {
  text-align: center;
}

.grid-title {
  color: #222222;
  letter-spacing: 1.5px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Montserrat';
  padding: 15px;
  padding-left: 25px;

  background-image: repeating-linear-gradient(
      -21deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      69deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      159deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    ),
    repeating-linear-gradient(
      249deg,
      #706b6b,
      #706b6b 8px,
      transparent 8px,
      transparent 14px,
      #706b6b 14px
    );
  background-size: 0px 100%, 100% 1px, 0px 100%, 100% 0px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.backgroundColor {
  background: linear-gradient(
    90deg,
    #de8000 -21.02%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.background-color-experience {
  background: black;
}

.hafla-experience {
  padding: 25px;
  border-radius: 16px 16px 0px 0px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  justify-content: center;
  font-size: 18px;
  color: #f5f5f5;
  letter-spacing: 0.15em;
}

.typical-experience {
  padding: 25px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  color: #f5f5f5;
  letter-spacing: 0.15em;
  border-radius: 16px 16px 0% 0%;
}

.home-background-overlay {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(56, 59, 64, 0.4);
  }
}

.height-container {
  .surface-web & {
    height: 13rem;
  }

  .surface-mobile & {
    height: 6rem;
  }
}

.ribbon-button-styles {
  align-items: center;
  font-weight: 400 !important;
  justify-content: space-evenly;
  .surface-web & {
    height: 44px;
    width: 264px;
  }
  .surface-mobile & {
    height: 22px;
    letter-spacing: 1px !important;
    margin-left: 0px;
    padding-left: 5px;
    width: 138px;
    padding: 10px 12px;
    background: linear-gradient(
      50deg,
      rgb(220, 124, 10) 7.01%,
      rgb(202, 87, 121) 56.57%,
      rgb(205, 76, 91) 100.37%
    ) !important;
  }
}

.fontSize {
  .surface-web & {
    height: 13rem;
  }

  .surface-mobile & {
    height: 6rem;
  }
}

.overlay-selected-event {
  position: absolute;
  background: rgba(238, 125, 147, 0.5);
  width: 100%;
  height: 100%;
}

.disable-icon {
  opacity: 0.5;
  cursor: none;
}

.profile-user-header {
  position: absolute;
  margin: auto;
  height: 65px;
  background: #f0f0f1;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  padding: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  top: 188px;
  line-height: 22px;
  color: #000000;
}

.profile-event-header {
  background: #495057;
  border-radius: 8px;
  padding: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;

  & > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.disable-bg {
  background: #eaeaea;
}

.remove-cursor {
  cursor: default !important; // added important due to inner component replaces the style
}

.player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 380px;
  height: 100%;
  border-radius: 8px;
  justify-self: anchor-center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.player-button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 380px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  opacity: 0;
  text-align: center;
  align-content: center;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    opacity: 1;
    position-area: center;
    width: 100%;
  }
}

.player-button-overlay:hover {
  opacity: 1;
}

.quote-button {
  border: 1px solid #ddd;
  float: right;
  cursor: pointer;
}

.label-border {
  border-width: 1px;
}

.bg-green-gradient {
  background-image: linear-gradient(48.54deg, #008ddc 20.07%, #32ff47 107.01%);
}

.bg-red-gradient {
  background-image: linear-gradient(90deg, #f2709c, #ff9472);
}

.bg-purple-gradient {
  background-image: linear-gradient(44.84deg, #9796f0 59.65%, #fbc7d4 107.01%);
}

.bg-red-gradient {
  background-image: linear-gradient(90deg, #f2709c, #ff9472);
}

.quote-dropdown {
  float: right;
  width: max-content;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

.padding-5 {
  padding: 0.5rem;
}

.order-detail {
  text-align: center;
  justify-content: center;
  margin: auto 10rem auto 10rem;
}

.same-line-div {
  display: flex;
  flex-direction: row;
}

.order-detail-error {
  margin: 0 0 3rem 4rem;
}

.price-note-field {
  width: '90%';
  height: '3em';
}

.price-label {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.price-label-color {
  color: #8e8e8e; // #TODO Ask viswa if we can make it conditional with above class.
}

.partner-font-style {
  font-size: 10px;
  color: #8e8e8e;
}

.order-row-justify {
  justify-content: space-between;
}

.amount-price {
  padding-left: 20px !important; // added due to overwrite by after css.
}

.role-customer {
  .notes-text-style {
    font-size: 13px;
    width: 45vw;
  }
}

.role-crm {
  .notes-text-style {
    font-size: 13px;
    width: 60vw;
  }
}

.bg-cart {
  background: linear-gradient(
    90deg,
    #ef8023 0%,
    #d95799 54.02%,
    #ea3d24 100.04%
  );
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notes-title-style {
  margin-right: 10px;
  font-size: 13px;
  width: 100px;
  align-self: center;
}

.note-background {
  background-color: #fff9c8;
}

.cart-item-style {
  width: 30%;
}

.placeholder-color {
  color: #aba9a9;
}

.notes-scroll {
  word-wrap: break-word;
  overflow-y: scroll;
  width: 1000px;
  height: 40px;
  border-width: 2px;
  padding: 3px;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-backdrop {
  z-index: 1;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.left-80 {
  left: 80% !important;
}

button:disabled,
button[disabled] {
  opacity: 0.6;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

@media (min-width: 768px) {
  .md\:w-66\.75 {
    width: 66.75rem;
  }

  .md\:pr-24 {
    padding-right: 6rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .overflow-scroll {
    overflow-y: scroll;
  }
}

.required-color {
  color: red;
}

.bg-neutral {
  background-color: #f3f3f6;
}

.shadow-event:hover {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11) !important;
}

@media (min-width: 768px) {
  .md\:-mt-24 {
    margin-top: -6rem !important;
  }

  .md\:w-47\.5 {
    width: 47.5rem;
  }

  .md\:self-baseline {
    align-self: baseline;
  }
}

.-mt-28 {
  margin-top: -7rem;
}

.reduce-gap {
  gap: 0px !important;
}

.-mt-10 {
  margin-top: -2rem;
}

.border-red-500 {
  border-color: red;
}

.sk-hits-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 10px;
  flex: 1;
}

@media (min-width: 768px) {
  .md\:w-56\.25 {
    width: 56.25rem;
  }
}

.self-baseline {
  align-self: baseline;
}

.text-light-purple {
  color: #6461e1;
}

.h-80vh {
  height: 80vh;
  max-height: 80vh;
  overflow-y: auto;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #fbeff2 transparent transparent;
}

.book-order-info-message {
  display: none;
}

.book-order-info:hover .book-order-info-message {
  display: block;
}

.banner-overlay-thumbnail {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.banner-overlay-thumbnail .image-gallery {
  background: #000;
}

.banner-overlay-thumbnail .image-gallery-slide img {
  object-fit: contain;
  width: 100%;

  .surface-web & {
    height: 536px;
  }
}

.banner-overlay-thumbnail-full-screen .image-gallery-slide img {
  object-fit: cover;
  width: 100%;

  .surface-web & {
    height: 100%;
  }
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
  background-color: white;
}

.banner-overlay-thumbnail .image-gallery-thumbnail-wrapper {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.banner-overlay-thumbnail .image-gallery-thumbnail {
  margin: 0 5px;
  border: 2px solid transparent;
}

.banner-overlay-thumbnail .image-gallery-thumbnail img {
  max-width: 100%;
  border-radius: 5px;
}

.banner-overlay-thumbnail .image-gallery-thumbnail.active {
  border-color: #007bff;
}

.image-gallery-thumbnails img {
  width: 128px !important;
}

.banner-overlay-thumbnail .image-gallery-thumbnail {
  margin: 0;
}

.banner-overlay-thumbnail .image-gallery-thumbnail.active {
  border-radius: 6px;
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right
  .image-gallery-thumbnails
  .image-gallery-thumbnail
  + .image-gallery-thumbnail {
  margin-top: 12px;
}

@media screen and (min-width: 0px) and (max-width: 720px) {
  .image-gallery-thumbnails {
    display: none;
  }
}

@media screen and (min-width: 721px) {
  .image-gallery-thumbnails {
    display: block;
  }

  .banner-overlay-thumbnail img {
    border-radius: 12px;
  }
}

@media (min-width: 768px) {
  .md\:top-24 {
    top: 6rem;
  }

  .md\:left-74 {
    left: 19.5rem;
  }
}

.w-126 {
  width: 31.25rem;
}

.text-info-base {
  color: #017aad;
}

.bg-info-light {
  background: #e5f5fc;
}

.checkout-event-type .multi-select-epm {
  height: 3.125rem;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.checkout-event-type .multi-select-epm .select2-selection.form-select__control {
  padding-top: 0.5rem;
  z-index: 999;
  position: relative;
}

.select2-selection .form-select__single-value {
  font-weight: 500;
  color: #000 !important;
}

.select2-selection .form-select__placeholder {
  font-weight: 500;
  color: #bababa;
}

.multi-select-epm {
  font-size: 0.875rem;
}

.z-101 {
  z-index: 9999;
}

.lead-capture select option:first-child,
.lead-capture select option {
  color: #000;
}

.dashboard-iframe {
  width: -webkit-fill-available;
  height: 100vh;
}
.web-quote-item-summary .w-15 {
  width: 10rem;
  height: 9rem;
  position: relative;
}
div.web-quote-item-summary .w-15 span {
  display: block !important;
}
div.web-quote-item-summary .w-15 span span {
  display: block !important;
  height: 9rem !important;
}
.web-quote-item-summary .w-15 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  div.web-quote-item-summary .w-15 span span {
    display: block !important;
    height: 6rem !important;
  }
  .web-quote-item-summary .w-15 {
    width: 7rem;
    height: 6rem;
    position: relative;
  }
}

.mr-4 {
  margin-right: 1rem;
}
div.web-quote-item-summary span.displayFlex {
  display: flex !important;
  justify-content: start;
}
div.web-quote-item-summary span.displayFlex span {
  height: 30px !important;
}

.rich-text-editor div:nth-child(2) {
  overflow: auto;
  padding-top: 0.5rem;
}
.css-1vo3iz0 {
  margin: 0px 5px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.rich-text-editor ol,
.rich-text-editor ul {
  padding: 2px 20px;
}

.css-1vo3iz0 {
  padding-bottom: 7px !important;
}
.h-10 {
  height: 2.5rem;
}
.overflow-auto {
  outline: none;
}
.terminate-event-dropdown .select__control {
  border: none;
}

.hero-background-web {
  height: 558px;
  background: linear-gradient(
    90deg,
    #de8000 -15%,
    #c85384 71.74%,
    #d93b00 154%
  );
}
@media screen and (min-width: 0px) and (max-width: 819px) {
  .hero-background-mobile {
    height: 328px;
    width: 100%;
    background: linear-gradient(
      76.02deg,
      rgba(243, 128, 35, 0.9) 1.66%,
      rgba(221, 87, 157, 0.9) 53.77%,
      rgba(238, 61, 36, 0.9) 99.92%
    );
  }
}
.hero-heading {
  font-family: 'google-basic';
  @media screen and (min-width: 0px) and (max-width: 819px) {
    line-height: 2rem;
    width: 80% !important;
  }
  width: 60% !important;
  line-height: 4rem;
}

.top-brands-header-web {
  letter-spacing: 4.4px;
}

.plan-with-ai-arrow-style {
  background: linear-gradient(
    90deg,
    #de8000 -15%,
    #c85384 71.74%,
    #d93b00 154%
  );
}

.plan-with-ai-text {
  background-image: linear-gradient(
    90deg,
    #de8000 -15%,
    #c85384 71.74%,
    #d93b00 154%
  );
  color: transparent;
  background-clip: text;
  letter-spacing: 0.1rem;
}

.plan-with-ai-style {
  font-family: 'google-basic';
  border: 2px solid transparent;
  line-height: 25px;
  background: conic-gradient(#fff 0 0) padding-box,
    linear-gradient(90deg, #f38023 -90.01%, #dd579d 205.57%, #ee3d24 467.37%)
      border-box;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    line-height: 10px;
    border: 1px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    width: 240px;
    height: 37px;
  }
}

@media screen and (min-width: 0px) and (max-width: 819px) {
  .trending-services-sub-title-mobile {
    font-size: 12px;
  }
  .trending-services-title-mobile {
    gap: 4px !important;
  }
}

@media (min-width: 820px) {
  .trending-services-sub-title-mobile {
    font-size: 14px;
  }
}

.gap-one {
  gap: 4px !important;
}

.gap-half {
  gap: 2px !important;
}

.gap-zero {
  gap: 0 !important;
}

.home-page-banner-image {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.bottom-overlay-banner-description {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(209, 111, 102, 0) 11.91%,
    #d36848 95.51%
  );
  height: 25%;
  z-index: 15;
  pointer-events: none;
  border-radius: 5px;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    height: 70%;
  }
}

.bottom-center-align {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 16;
  transform: translateX(-50%);
  width: max-content;
}

.bottom-overlay {
  position: relative;
  display: inline-block;
}

.bottom-overlay img {
  display: block;
  border-radius: 8px;
  height: auto;
}

.bottom-overlay::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 40%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: 50;
  width: -webkit-fill-available;
  border-radius: 8px;
  justify-self: center;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    border-radius: 8px;
    height: 70%;
  }
}

.banner-image-web {
  position: absolute;
  right: 0px;
  transform: scaleX(-1) translateX(0%);
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    animation: marquee 10s linear infinite;
  }
}

.bounce-animation {
  display: inline-block;
  transition: transform 0.2s ease;
}

.bounce-animation:hover {
  transform: translateY(-15px);
}

.inner-content {
  border-radius: 8px;
  transform-origin: center;
  overflow: hidden;
}

.gradient-border:hover {
  border: 1.5px solid transparent;
  border-radius: 10px;
  background: conic-gradient(#fff 0 0) padding-box,
    linear-gradient(90deg, #e58023 -33.51%, #cf578f 94.65%, #e03d24 207.46%)
      border-box;
}

.gradient-border-featured-categories {
  position: relative;
  font-size: 0;
}

.gradient-border-featured-categories:hover::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1.5px;
  background: linear-gradient(
    90deg,
    #e58023 -33.51%,
    #cf578f 94.65%,
    #e03d24 207.46%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.gradient-border-featured-categories a {
  line-height: 1 !important;
}

.gradient-container:hover img {
  transform: scale(1.2);
}

.inner-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: block;
}

.blog-ribbon-button-style {
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400 !important;
  .surface-web & {
    height: 44px;
    width: 214px;
    letter-spacing: 2.1px !important;
  }
}

.top-brands-header-mobile {
  letter-spacing: 1.9px;
  padding-top: 18px;
  @media screen and (min-width: 0px) and (max-width: 370px) {
    padding-top: 40px;
  }
  @media screen and (min-width: 371px) and (max-width: 819px) {
    padding-top: 25px;
  }
}

.table-container table {
  border-color: rgba(246, 246, 246, 1);
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

td {
  border: 1px solid rgba(246, 246, 246, 1);
  padding: 8px;
  transition: border-color 0.3s ease;
}

.video-player-container {
  width: 380px;
  height: 240px;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    width: 260px;
    height: 164px;
  }
}

.event-glimpses-video-grid-item:nth-child(1),
.event-glimpses-video-grid-item:nth-child(3) {
  margin-top: 35px;
}

.event-glimpses-video-grid-item:nth-child(5) {
  margin-top: -15px;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    margin-top: -37px;
  }
}
.event-glimpses-video-grid-item:nth-child(4),
.event-glimpses-video-grid-item:nth-child(6) {
  margin-top: 25px;
  @media screen and (min-width: 0px) and (max-width: 819px) {
    margin-top: 0px;
  }
}

.wave-border {
  position: relative;
  overflow: hidden;
}

.wave-border:before {
  position: absolute;
  top: -0.5px;
  right: 0;
  left: 0;
  height: 0.5px;
  background: linear-gradient(
        to left,
        rgba(222, 128, 0, 0.2),
        rgba(200, 83, 132, 0.2),
        #fff
      )
      0 0 / 200% 100%,
    linear-gradient(
        to left,
        rgba(222, 128, 0, 0.2),
        rgba(200, 83, 132, 0.2),
        #fff
      )
      0 100% / 200% 100%;
  animation: wave-shine-top 10s linear infinite;
}

.wave-border:after {
  content: '';
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  height: 0.5px;
  background: linear-gradient(
        to left,
        rgba(222, 128, 0, 0.2),
        rgba(200, 83, 132, 0.2),
        #fff
      )
      0 0 / 200% 100%,
    linear-gradient(
        to left,
        rgba(222, 128, 0, 0.2),
        rgba(200, 83, 132, 0.2),
        #fff
      )
      0 100% / 200% 100%;
  animation: wave-shine-bottom 10s linear infinite;
}

@keyframes wave-shine-top {
  0% {
    background-position: 200% 0, 200% 100%;
  }
  100% {
    background-position: 0 0, 0 100%;
  }
}

@keyframes wave-shine-bottom {
  0% {
    background-position: 200% 0, 200% 100%;
  }
  100% {
    background-position: 0 0, 0 100%;
  }
}

tr:first-child td,
tr:first-child th {
  border-top: none;
}

tr:last-child td,
tr:last-child th {
  border-bottom: none;
}

td:first-child,
th:first-child {
  border-left: none;
}

td:last-child,
th:last-child {
  border-right: none;
}

.event-glimpses-column-container {
  display: flex;
  gap: 10px;
}

.event-glimpses-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 819px) {
  .event-glimpses-column-container {
    flex-direction: column;
  }
}

.trending-collection-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.trending-collection-tile {
  min-width: 354px;
}

@media (max-width: 819px) {
  .trending-collection-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(100%, 1fr);
    gap: 10px;
    overflow-x: auto;
    width: 100vw;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 20px;
    margin-left: 20px;
  }

  .trending-collection-tile {
    min-width: 280px;
    max-width: 280px;
    padding: 5px;
    scroll-snap-align: start;
  }

  trending-collection-container::-webkit-scrollbar {
    display: none;
  }
}

.view-all-button-container {
  width: 93px !important;
  height: 22px !important;
}

.why-choose-hafla-mobile-header {
  line-height: 23px !important;
}
