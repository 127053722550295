#product-card .carousel .control-dots .dot.selected {
  background: linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);
  width: 12px;
  border-radius: 4px;
}

#product-card .carousel .control-dots .dot:hover {
  background: linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%);
}

#product-card .carousel .control-dots .dot {
  margin: 0px 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.26) !important;
  background-color: #E4E4E4;
  width: 6px;
  height: 6px;
}


@media only screen and (min-width: 768px) {

  #product-card .carousel .control-dots {
    bottom: auto;
    margin: 0px;
    top: 180px;
    display: flex;
    justify-content: center;
  }

}


@media only screen and (max-width: 767px) {

  #product-card .carousel .control-dots {
    bottom: auto;
    top: 160px;
    margin: 0px !important;
    display: flex;
    justify-content: center;
  }

}

.image-bid-detail .slide img,
.image-bid-detail .slide,
.image-bid-detail .slider,
.image-bid-detail .slide .selected {
  border-radius: 12px 12px 0px 0px !important;
}

.image-bid-summary .slide .selected {
  border-radius: 12px 0px 0px 12px !important;
}

.slide {
  overflow: hidden !important;
}